










































import md5 from 'md5'
import store from '@/store'
import {checkAccount, selectAccount} from '@/request/Login'
export default {
    name: "Login",
    data() {
        return {
            loading: false,
            checkAccountModal: {
                show: false,
                accountList: []
            },
            formData: {
                acc: '',
                pass: ''
            },
            formRules: {
                acc: {required: true, message: '请输入账号', trigger: 'blur'},
                pass: {required: true, message: '请输入密码', trigger: 'blur'}
            }
        }
    },
    created() {
        document.documentElement.style.overflow='hidden';
    },
    beforeRouteEnter(to: any, from: any, next: any) {
        next()
    },
    beforeRouteLeave(to: any, from: any, next: any) {
        let self: any = this;
        setTimeout(() => self.logining = false, 2000);
        next()
    },
    methods: {
        submit() {
            let self: any = this;
            self.$refs['form'].validate((valid: boolean) => {
                if (valid) {
                    self.loading = true;
                    let account: any = {...self.formData};
                    account['pass'] = md5(self.formData['pass']);
                    checkAccount(account).then((body: any) => {
                        self.loading = false;
                        if (body.code === 0) {
                            let data = body.data;
                            if (data.length === 1) {
                                self.selectAccount2Login(data[0].merchantId, data[0].employeeId);
                            } else if (data.length > 1) {
                                self.checkAccountModal.show = true;
                                self.checkAccountModal.accountList = data;
                            }
                        }
                    })
                }
            })
        },
        selectAccount2Login(merchantId: string, employeeId: string) {
            let self: any = this;
            self.loading = true;
            self.$Message.loading({content: '请稍后'});
            setTimeout(() => { //让节奏慢1秒吧
                selectAccount({
                    merchantId: merchantId,
                    employeeId: employeeId
                }).then((body: any) => {
                    self.loading = false;
                    self.$Message.destroy();
                    if (body.code === 0) {
                        let data = body['data'];
                        self.$Message.info(`欢迎 ${data.name}`);
                        sessionStorage.setItem(store.getters.info, JSON.stringify(data));
                        self.$router.replace('/index');
                    } else {
                        self.$Modal.warning({
                            title: '提示',
                            content: body.msg,
                        })
                    }
                })
            }, 1000);
        },
        forgetPwd() {
            let self: any = this;
            self.$Modal.info({
                title: '提示',
                content: '员工忘记密码找管理员重置, 管理员忘记密码请联系平台'
            });
        }
    }
}
